import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth ,db} from './firebaseConfig';
import { signOut } from 'firebase/auth';
import { doc, setDoc,getDoc } from 'firebase/firestore'; 
import '../src/App.css'; // Adjust the path if needed

const Login = ({setTest}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  // const [isUserLoggedIn, setIsUserLoggedIn] = useState(!!localStorage.getItem('userEmail'));
  const [token , settoken] = useState(null)
  const navigate = useNavigate();

  // setIsUserLoggedIn(!!localStorage.getItem('userEmail'));


  const getTokenFromFirebase = async () => {
    if (!auth.currentUser) {
      console.error('User is not authenticated.');
      return null; 
    }
  
    const userId = auth.currentUser.uid; 
    const userRef = doc(db, 'users', userId);
    
    try {
      const docSnapshot = await getDoc(userRef);
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        console.log(userData, '---------------');
        
        // Retrieve the access token from the userData
        const token = userData.access_token; // Corrected key to access the token
        console.log('Retrieved token:', token);
        
        if (token) {
          navigate('/Dashboard'); // Navigate to Dashboard if token exists
        } else {
          navigate('/verify-license'); // Navigate to verify-license if token is not found
        }
        
      } else {
        console.log("No such document! Token not found.");
        navigate('/verify-license');
      }
    } catch (err) {
      console.error('Error retrieving token:', err);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log(user.uid, '--------------------------------')
      
      if (!user.emailVerified) {
        setError('Please verify your email before logging in.');
        await auth.signOut();
        return;
      }
  
      localStorage.setItem('userId', user.uid);
      const token = await getTokenFromFirebase();
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message);  
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h2>Welcome back, Login</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <form onSubmit={handleLogin}>
          <div className='margin-space' >
            <label>Email:</label>
            <br></br>
            <input
              type="email"
              placeholder="You @email.com "
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Password:</label>
            <br></br>
            <input
              type="password"
              placeholder=" Your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="full-width-button">Login</button>
        </form>
        <p>Don't have an account? <Link className='link-color' to="/register">Sign up</Link></p>
      </div>
    </div>
  );
};

export default Login;
