import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const VerifyLicense = () => {
  const [productId] = useState('Xud30hZ7g9fOryJ7WrlCKw==');
  const [licenseKey, setLicenseKey] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [verified, setVerified] = useState(false); 
  const [notionResult, setNotionResult] = useState(null);
  const [successMessage, setSuccessMessage] = useState(''); 
  const navigate = useNavigate();
  // New state for the additional input field
  const [notionInput, setNotionInput] = useState('');

  const handleVerify = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setResult(null);
    setVerified(false); 
    setSuccessMessage('');
    

    try {
      const response = await axios.post('https://api.gumroad.com/v2/licenses/verify', {
        product_id: productId,
        license_key: licenseKey,
      });

      if (response.status === 200 && response.data.success) {
        setVerified(true); 
        setSuccessMessage('License verified successfully!');
        setError(null);
      } else {
        setError('License verification failed');
      }
    } catch (err) {
      setError(err.response ? err.response.data.message : 'Failed to verify license');
      setResult(null);
    } finally {
      setLoading(false);
    }
  };

  const handleNotionConnect = () => {
    // Log the value of the input field
    console.log('Notion Input:', notionInput);
    navigate('/dashboard', { state: { notionInput } });
    // const notionAuthUrl =
    //   'https://api.notion.com/v1/oauth/authorize?client_id=105d872b-594c-80ea-831c-00372f102a90&response_type=code&owner=user&redirect_uri=http://localhost:3001/dashboard';
    // window.location.href = notionAuthUrl;
  };

  return (
    <div>
      <div className="verify-contain">
        <div className="verify-license">
          <h2>Step 1</h2>
          <p>
            Duplicate the template to your workspace.<br></br> Here are the instructions.
          </p>
          <h2>Step 2</h2>
          <p>
            Submit your license key here. You can find <br></br> your instruction key from your contents page <br></br> in Gumroad.
          </p>
          <form onSubmit={handleVerify}>
            <div className="margin-space">
              <label>License Key:</label>
              <br></br>
              <input
                type="text"
                value={licenseKey}
                onChange={(e) => setLicenseKey(e.target.value)}
                required
              />
            </div>
            <button className="full-width-button" type="submit" disabled={loading}>
              {loading ? 'Verifying...' : 'Verify License'}
            </button>
          </form>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

          <div className='margin-top-space'>
            <h2>Step 3</h2>
            <p>
      <a href="https://disco-parcel-eda.notion.site/Setup-Instruction-11133e69ad4d800d814fef778b10e398?pvs=4" target="_blank" rel="noopener noreferrer">
        Follow the instruction 
      </a>{' '}

      and generate a Secret key.
    </p>

            {/* New input field for Notion */}
            <div className="margin-space">
              <label>Secret Key</label>
              <br></br>
              <input
                type="text"
                value={notionInput}
                onChange={(e) => setNotionInput(e.target.value)}
                placeholder="Enter Notion Secret key"
              />
            </div>

            {result && <pre>{JSON.stringify(result, null, 2)}</pre>}

            <button
              className={`full-width-button ${verified ? 'enabled' : 'disabled'}`}
              onClick={handleNotionConnect}
              disabled={!verified} 
            >
              Connect with Notion
            </button>
          </div>
          {notionResult && <p>{notionResult}</p>}
        </div>
      </div>
    </div>
  );
};

export default VerifyLicense;
