import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import { signOut } from 'firebase/auth';
import Register from './Register';
import Dashboard from './Dashboard';
import VerifyLicense from './VerifyLicense';
import Test from './Test';
import { auth , db} from './firebaseConfig';
import { doc, setDoc,getDoc } from 'firebase/firestore'; 
import './App.css';

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          {/* Login Route */}
          <Route 
            path="/login" 
            element={<Login/>} 
          />

          {/* Register Route */}
          <Route 
            path="/register" 
            element={<Register />} 
          />

          {/* Unprotected route for test */}
          <Route 
            path="/test" 
            element={<Test />} 
          />

          {/* Dashboard Route */}
          <Route 
            path="/dashboard" 
            element={<Dashboard />} 
          />

          {/* Verify License Route */}
          <Route 
            path="/verify-license" 
            element={<VerifyLicense />} 
          />

          {/* Default Route Redirecting to Login */}
          <Route 
            path="/" 
            element={<Navigate to="/login" />} 
          />

          {/* Catch-all Route Redirecting to Login */}
          <Route 
            path="*" 
            element={<Navigate to="/login" />} 
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
