import React, { useState, useEffect } from 'react';
import { signOut } from 'firebase/auth';
import { auth , db} from './firebaseConfig';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner';
import { doc, setDoc,getDoc } from 'firebase/firestore'; 
import { getAuth } from "firebase/auth";
import { useLocation } from 'react-router-dom';

const Dashboard = () => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('quilting'); 
  const [buttonLoading, setButtonLoading] = useState({}); 
  const [userid, setuserId] = useState(null);
  const [token , setToken] = useState(null)

  const navigate = useNavigate();
  const location = useLocation();
  const { notionInput } = location.state || {};
  const extractTokenFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('code');
  };

  // const saveTokenToFirebase = async () => {
  //   const auth = getAuth();
  //   const currentUser = auth.currentUser;
  
  //   if (currentUser) {
  //     const userId = currentUser.uid; 
  //     console.log("User ID: ", userId); 
  //     setuserId(userId);                
  //   } else {
  //     console.log("No user is logged in."); 
  //   }
  // };

  useEffect(() => {
    const userid = localStorage.getItem('userId');
    console.log(userid, '00000000000000000')
    if (!userid) {  
      navigate("/login");
    }
    const token = extractTokenFromUrl();
    setToken(token)
  }, []);

  const fetchBooks = async (query) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`https://www.googleapis.com/books/v1/volumes?q=${query}`);
      const data = await response.json();
      setBooks(data.items || []);
    } catch (err) {
      setError('Failed to fetch books');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBooks(searchTerm);
  }, [searchTerm]);

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/login');
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    fetchBooks(searchTerm);
  };

  const addToNotion = async (book, listType, bookId) => {
    const { title, description, industryIdentifiers, imageLinks, publisher, publishedDate, authors, pageCount, categories, language } = book.volumeInfo;
    const { retailPrice } = book.saleInfo;
    

    const bookDetails = {
      token,
      title,
      description: description && description.length > 1999 ? description.substring(0, 1999) + '...' : description || 'No description available',
      isbn: industryIdentifiers ? industryIdentifiers[0].identifier : 'Unknown ISBN',
      previewLink: imageLinks && (imageLinks.large || imageLinks.small || imageLinks.thumbnail) 
              ? (imageLinks.large || imageLinks.small || imageLinks.thumbnail) 
              : "no image",
      publisher: publisher || 'Unknown Publisher',
      publishedDate: publishedDate || 'Unknown Publisher',
      authors: authors || ['Unknown Author'],
      page_count: pageCount || 0,
      language: language || 'Unknown Language',
      status: listType === 'library' ? 'Library' : 'Wishlist',
      tags: ['Fiction', 'Adventure'],
      due_date: '2024-09-25',
      price: retailPrice ? retailPrice.amount: "Price Not Found",
      currency: retailPrice ? retailPrice.currencyCode: "USDT",
      genre: categories || ['Unknown genre'],
    };
    console.log(bookDetails.retailPrice, '==============================')

    const userid = localStorage.getItem('userId');
    console.log(userid, '------------')
    const queryString = new URLSearchParams({
      code: notionInput || "no input",
      userId:userid,
      title: bookDetails.title,
      isbn: bookDetails.isbn,
      previewLink: bookDetails.previewLink,
      description: bookDetails.description,
      publisher: bookDetails.publisher,
      publishedDate: bookDetails.publishedDate,
      authors: bookDetails.authors.join(', '),
      page_count: bookDetails.page_count.toString(),
      language: bookDetails.language,
      status: bookDetails.status,
      due_date: bookDetails.due_date,
      tags: bookDetails.tags.join(', '),
      price: bookDetails.price,
      currency:bookDetails.currency,
      genre: bookDetails.genre.join(', '),
    }).toString();

    // console.log(bookDetails.price, '------------------------------------------------')
    const apiUrl = `https://widgethub.co/api/add-book?${queryString}`;
    // const apiUrl = `http://127.0.0.1:5000/api/add-book?${queryString}`;
    setButtonLoading((prev) => ({ ...prev, [`${listType}-${bookId}`]: true }));

    try {
      await axios.get(apiUrl);
      toast.success(`Book added to ${listType === 'library' ? 'Library' : 'Wish List'} successfully!`);
    } catch (err) {
      toast.error(`Failed to add book: ${err.message}`);
    } finally {
      // Set button-specific loading to false after the request completes
      setButtonLoading((prev) => ({ ...prev, [`${listType}-${bookId}`]: false }));
    }
  };

  return (
    <div className="book-track">
      <ToastContainer />
      <div className="search-container">
        <form onSubmit={handleSearch}>
          <div className="search-box">
            <img src="search-svgrepo-com 1.png" alt="Search Icon" className="search-icon" />
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </form>
      </div>

      <h2 className="border">Bestsellers</h2>

      {loading && <Circles color="#3498db" height={50} width={50} /> } {/* Show loader when loading */}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="book-wrap" style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', maxWidth: '388px', width: '100%', padding: '20px' }}>
          {books.map((book) => {
            const { id, volumeInfo } = book;
            const { title, authors, imageLinks } = volumeInfo;

            return (
              <div className="book-parent" key={id} style={{ display: 'flex', padding: '20px', width: '100%' }}>
                {imageLinks && imageLinks.thumbnail ? (
                  <img src={imageLinks.thumbnail} alt={title} style={{ width: '100px', height: '150px', marginRight: '20px' }} />
                ) : (
                  <div style={{ width: '100px', height: '150px', backgroundColor: '#f0f0f0', marginRight: '10px' }} />
                )}

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <div>
                    <h4 className="book-title">{title}</h4>
                    <p className="book-para">{authors ? authors.join(', ') : 'Unknown Author'}</p>
                  </div>
                  <div>
                    <button
                      className="library"
                      onClick={() => addToNotion(book, 'library', id)}
                      disabled={buttonLoading[`library-${id}`]} // Disable button when loading
                      style={{ marginRight: '10px' }}
                    >
                      {buttonLoading[`library-${id}`] ? <Circles color="#fff" height={11} width={50} /> : 'Library'}
                    </button>
                    <button
                      className="wishlist"
                      onClick={() => addToNotion(book, 'wish_list', id)}
                      disabled={buttonLoading[`wish_list-${id}`]} // Disable button when loading
                    >
                      {buttonLoading[`wish_list-${id}`] ? <Circles color="#000" height={11} width={50} /> : 'WishList'}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
