// Import the necessary functions from the Firebase SDK
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';  // Import the getAuth function
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore'; // Import Firestore

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCnZ0xY07sMcVlRGwAH6YVuCSyKndHBxcg",
  authDomain: "bookstracker-4292e.firebaseapp.com",
  projectId: "bookstracker-4292e",
  storageBucket: "bookstracker-4292e.appspot.com",
  messagingSenderId: "360290890958",
  appId: "1:360290890958:web:c81da87a37f70bf1e6cc9f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);

export const db = getFirestore(app);
